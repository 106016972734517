import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { tap } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {

  constructor(
    public router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('tkn')
    let headers: any;
    if (token) {
      headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
    }


    const reqClone = req.clone({
      headers
    });

    return next.handle(reqClone).pipe(
      tap(
        (event) => {
        },
        (error: HttpErrorResponse) => {
          console.log(error.status);
          if (error.status == 403 || error.status == 401) {
            sessionStorage.clear();
            //localStorage.clear();
            this.router.navigate(['']);
          }
        }
      ),
      catchError(this.handleError)
    );

  }

  handleError(error: HttpErrorResponse) {
    console.log('An error occurred');
    console.log('Recorded in the log file');
    console.warn(error);
    return throwError(() => error);
  }

}
