import { Component } from '@angular/core';
import { CatalogModel } from '../../shared/models/catalog.model';
import { retry } from 'rxjs';
import { CatalogService } from '../../shared/services/catalog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralUtils } from '../../shared/utils/generalUtils';
import { BookingService } from '../../shared/services/booking.service';
import { BookingModel } from '../../shared/models/booking.model';
import { StatusCatalogEnum } from '../../shared/enums/status.catalog.enum';
import { BookingUtils } from '../../shared/utils/bookingUtils';
import { CatalogUtils } from '../../shared/utils/catalogUtils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  isFull: boolean = false;
  isFull1: any;
  isFull3: boolean = false;
  isFull4: boolean = false;
  userdata: any;
  ud: any;
  ccode: string = "";
  ucode: string = "";
  tokn: string = "";
  bookingStatusList!: CatalogModel[];
  bookings!: BookingModel[];
  allBookingRoute: string = "";

  constructor(
    private catalogService: CatalogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private generalUtils: GeneralUtils,
    private bookingService: BookingService,
    private bookingUtils: BookingUtils,
    private catalogUtils:CatalogUtils
    // private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementsByClassName('page-loader-wrapper')[0].classList.remove("ShowDiv");
    }, 2000);

    //console.log(sessionStorage.getItem('tkn'));
    this.ccode = this.activatedRoute.snapshot.params['ccode'];
    this.ucode = this.activatedRoute.snapshot.params['ucode'];
    this.tokn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + this.ccode + '_' + this.ucode) || '');
    this.ud = sessionStorage.getItem('userdata') || (localStorage.getItem('userdata_' + this.ccode + '_' + this.ucode) || '');
    this.userdata = this.ud == '' ? '' : JSON.parse(this.ud);

    if (this.userdata.length != 0) {
      this.bookingStatusList = JSON.parse(sessionStorage.getItem('bookingStatusList') || (localStorage.getItem('bookingStatusList_' + this.ccode + '_' + this.ucode) || ''));
      this.allBookingRoute = '/' + this.ccode + '/' + this.ucode + '/' + '/bookings/all-bookings';
    } else {
      this.router.navigate(['']);
    }
  }

  closeMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove("offcanvas-active");
  }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown')?.classList.toggle("show");
  }

  fullScreenSection(number: number) {
    if (number == 1) {
      if (this.isFull) {
        this.isFull = false;
      }
      else {
        this.isFull = true;
      }
    }
    else if (number == 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      }
      else {
        this.isFull1 = true;
      }
    }
    else if (number == 3) {
      if (this.isFull3) {
        this.isFull3 = false;
      }
      else {
        this.isFull3 = true;
      }
    }
    else if (number == 4) {
      if (this.isFull4) {
        this.isFull4 = false;
      }
      else {
        this.isFull4 = true;
      }
    }
  }

  setBadgeType(badge: any): string {
    let badgeIndex = Object.keys(StatusCatalogEnum).indexOf(badge);
    let badgeCode = Number(Object.values(StatusCatalogEnum)[badgeIndex]);
    return this.bookingUtils.badgeTypeSet(badgeCode);
  }

  random(): number {
    const minCeiled = Math.ceil(0);
    const maxFloored = Math.floor(50);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
  }
}
