import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { Error404Component } from './core/components/error404/error404.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  // main routes
  {
    path: 'login',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: ':ccode/:ucode',
    children: [
      {
        path: 'dashboard',
        data: { title: 'B.T.S. | Dashboard' },
        component: DashboardLayoutComponent
      },
      {
        path: 'bookings',
        loadChildren: () =>
          import('./modules/booking/booking.module').then(
            (m) => m.BookingModule
          ),
      },
      {
        path: 'users',
        canActivate:[AuthGuard],
        loadChildren: () =>
          import('./modules/user/user.module').then(
            (m) => m.UserModule
          ),
      },
    ],
  },
  {
    path: '**',
    data: { title: 'B.T.S. | Not found' },
    component: Error404Component
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
