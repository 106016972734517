<div class="auth-main">
    <div class="auth_div vivify fadeIn">
        <div class="auth_brand">
            <a class="navbar-brand" href="#" style="display: inline-grid;"><img src="assets/images/online-booking.png"
                    class="d-inline-block align-top mr-2" alt="S.B.T" height="150">B.T.S.</a>
            <br>
            <span>BOOKINGS TRACKING SYSTEM<br><small>V2.0</small></span>
        </div>
      <router-outlet></router-outlet>
    </div>
    <div class="animate_lines" style="--primary-color:darkslategray;--secondary-color:lightgreen">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>