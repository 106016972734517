import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { CoreModule } from '../../core/core.module';
import { RouterModule } from '@angular/router';
import { DashboardModule } from '../../modules/dashboard/dashboard.module';




@NgModule({
  declarations: [
    DashboardLayoutComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    DashboardModule
  ]
})
export class DashboardLayoutModule { }
