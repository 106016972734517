import { Component, OnInit } from '@angular/core';
import { retry } from 'rxjs/operators';
// import { ToastrService } from 'ngx-toastr';
import { CatalogModel } from '../../shared/models/catalog.model';
import { CatalogService } from '../../shared/services/catalog.service';
import { DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';



@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
  ngOnInit(): void {}
}
