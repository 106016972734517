import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, retry } from 'rxjs';
import { UserService } from '../services/user.service';
import { UserModel } from '../models/user.model';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let code = atob(next.params['ucode']);
    let tkn = sessionStorage.getItem('tkn') || (localStorage.getItem('tkn_' + next.params['ccode'] + '_' + next.params['ucode']) || '');
    this.userService.getUserById(tkn, code) .subscribe(
      (result) => {
        if(result.rolCodigo==4){
          return true;
        }else{
          this.toastr.error('Access not allowed.');
          return this.router.navigateByUrl((next.params['ccode']+'/'+next.params['ucode']+'/dashboard')).then(() => false);
        }
      },
      (error) => {
      }
    );
    return true;
  }
}
