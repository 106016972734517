import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { DashboardComponent } from './dashboard.component';
import { UserComponent } from '../user/user.component';
import { RouterModule } from '@angular/router';
import { LineChartComponent } from '../../core/components/charts/line-chart/line-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BarChartComponent } from '../../core/components/charts/bar-chart/bar-chart.component';
import { ColumnChartComponent } from '../../core/components/charts/column-chart/column-chart.component';
import { RadarChartComponent } from '../../core/components/charts/radar-chart/radar-chart.component';

@NgModule({
  declarations: [DashboardComponent, LineChartComponent, BarChartComponent, ColumnChartComponent, RadarChartComponent],
  imports: [CommonModule, CoreModule, RouterModule, NgApexchartsModule],
  exports:[DashboardComponent]
})
export class DashboardModule { }
